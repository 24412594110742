<template>
    <div>
        <van-nav-bar
                placeholder
                fixed
                left-arrow
                :border='false'
                title="提现明细"
                z-index="99"
                class="header"
                @click-left="$router.go(-1)">
            <template #left>
                <van-icon name="arrow-left" size="25" color="#0A1C33"/>
            </template>
        </van-nav-bar>
        <div class="div">
            <van-pull-refresh v-model="refreshing" success-text="刷新成功" @refresh="onRefresh">
                <van-list
                        v-model="loading"
                        :finished="finished"
                        :immediate-check="false"
                        :finished-text="finished_text"
                        @load="onLoad"
                >
                    <template v-for="item in indexList">
                        <div>
                            <div class="subsidiary" @click="subsidiarydetailsClick(item)">
                                <div class="subsidiary-top">
                                    <p class="subsidiary-title">提现{{item.coin}}书豆</p>
                                    <p>
<!--                                        <van-icon name="arrow" color="#909399" size="15"/>-->
                                    </p>
                                </div>
                                <div class="subsidiary-top subsidiary-button">
                                    <p class="subsidiary-text yellow" v-if="item.status == 0">审核中</p>
                                    <p class="subsidiary-text" v-if="item.status == 1">提现成功</p>
                                    <p class="subsidiary-text red" v-if="item.status == 2">提现失败</p>
                                    <p class="subsidiary-time">{{item.created_at}}</p>
                                </div>
                            </div>
                        </div>
                    </template>

                    <div class="no">
                        <No title="暂无提现记录" v-if="indexList.length == 0"></No>
                    </div>
                </van-list>
            </van-pull-refresh>
        </div>
    </div>
</template>

<script>
    import No from '../../components/No'
    export default {
        components:{
            No,
        },
        name: "Subsidiary",
        data() {
            return {
                page: 1,
                page_size: 10,
                indexList: [],
                moreList_show: false,
                refreshing: false,
                loading: false,
                finished: false,
                finished_text: ''
            }
        },
        created() {
            this.logs()
        },
        methods: {
            logs() {
                this.$axios.post('/api/withdraw/logs', {
                    page: this.page,
                    page_size: this.page_size,
                })
                    .then(res => {
                        var res = res.data.data
                        if (res.length < this.page_size) {
                            this.finished = true
                            this.finished_text = '没有更多了'
                        } else {
                            this.finished = false
                        }
                        if (this.page == 1) {
                            this.finished_text = ''
                        }

                        this.refreshing = false
                        this.loading = false;
                        this.listList = res
                        if (this.moreList_show) {
                            this.indexList = res
                            this.moreList_show = false
                        } else {
                            this.indexList = this.indexList.concat(res)
                        }
                    })
            },
            onRefresh() {
                //下拉刷新
                this.page = 1
                if (this.indexList.length >= this.count) {
                    this.finished = true
                } else {
                    this.finished = false
                }
                this.moreList_show = true
                this.logs()
            },
            onLoad() {
                if (this.finished) return
                this.page++;
                this.logs();
            },
            // subsidiarydetailsClick(item) {
            //     this.$router.push({
            //         path: '/subsidiarydetails',
            //         query:{
            //             id:item.id
            //         }
            //     })
            // }
        }
    }
</script>

<style scoped>
    .yellow {
        color: #FCAD0F !important;
    }

    .red {
        color: #D53D3C !important;
    }

    .subsidiary-button {
        margin-top: 30px;
    }

    .subsidiary-time {
        font-size: 24px;
        color: #BFC2CC;
    }

    .subsidiary-text {
        color: #4D77FD;
        font-size: 24px;
    }

    .subsidiary-title {
        color: #606266;
        font-size: 28px;
    }

    .subsidiary-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .subsidiary {
        padding: 35px 0;
        border-bottom: 1px solid #F2F5F8;
    }
</style>